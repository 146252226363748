<template>
  <v-card shaped class="post-card mx-auto mb-15">
    <v-img class="white--text align-end" height="300px" :src="post.imageUrl" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)">
      <v-card-title class="post-title">{{post.title}}</v-card-title>
    </v-img>
    <v-card-subtitle v-if="post.subtitle">{{post.subtitle}}</v-card-subtitle>

    <v-card-text v-if="post.body">
      {{post.body}}
    </v-card-text>
    
    <v-card-actions class="pb-6 mx-2" v-if="post.buttonLink">
      <v-btn shaped color="#3f523d" dark depressed block x-large :href="post.buttonLink" target="_blank" >{{post.buttonText}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'postFull',
  props: [
    'post'
  ],
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
  }
}
</script>

<style lang="scss">
.post-title {
  word-break: normal;
  font-size: 4.4vw;
  text-shadow: 1px 1px 14px #000000;
  line-height: 1.5;
}
</style>