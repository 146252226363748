<template>
  <v-app id="mobile-tab">
    <v-main id="main" class="main-bg">

      <v-bottom-navigation color="#f5f5f5" grow>
      <v-btn height="56" href="https://rau.rayoga.com" target="_blank">
        <span>Ra University</span>
        <v-icon>mdi-school</v-icon>
      </v-btn>
      <v-btn height="56" href="https://apps.apple.com/us/app/ra-yoga-live/id1548081348" target="_blank">
        <span>Ra Live</span>
        <v-icon>mdi-television-classic</v-icon>
      </v-btn>
      <v-btn height="56" href="https://retail.rayoga.com" target="_blank">
        <span>Retail</span>
        <v-icon>mdi-storefront</v-icon>
      </v-btn>
    </v-bottom-navigation>
      <v-container fluid>
        <v-row class="justify-center">
          <v-col cols="12">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.accessToken
    },
  },
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    }
  }
}
</script>

<style lang="sass">
@import url("https://use.typekit.net/gux3riu.css")

body

.theme--light.v-application 
  background-color: #f8f4ef
.text--center
  text-align: center

.theme--light.v-bottom-navigation,
.theme--light.v-btn.v-btn--has-bg
  background-color: #3f523d

.theme--light.v-bottom-navigation
  height: auto !important
  padding-top: 10px
  padding-bottom: 10px

.v-item-group.v-bottom-navigation span.v-btn__content
  background-color: rgba(255,255,255,0.2)
  margin-top: 5px
  margin-bottom: 5px
  border-radius: 10px

.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active)
  color: rgba(255,255,255,0.8) !important
</style>