<template>
  <v-container fluid>
    <v-row>
      <v-container cols="12">
        <v-row class="text-center" v-if="postsLoading">
          <v-col>
            <heart-loader />
          </v-col>
        </v-row>
        <v-row v-if="!postsLoading">
          <v-col cols="12" v-for="post in posts" :key="post.id">
            <post-full v-if="post.body !== null" :post="post"></post-full>
            <post-image v-if="post.body === null || post.body.length === 0" :post="post"></post-image>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import postFull from '../components/postFull.vue'
import postImage from '../components/postImage.vue'
import heartLoader from '../components/heartLoader.vue'

export default {
  components: {
    postFull,
    postImage,
    heartLoader,
  },
  name: 'DashboardPage',
  computed: {
    ...mapGetters({
      posts: 'fifth-tab-post/list',
    }),
    postsLoading() {
      return this.$store.state['fifth-tab-post'].isFindPending
    },
    marianaTekLoginHref() {
      return `${this.$store.state.session.marianaTekUri}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&code_challenge=${this.challenge}&code_challenge_method=plain`
    },
    clientId() {
      return this.$store.state.session.clientId
    },
    state() {
      return this.$store.state.session.state
    },
    challenge() {
      return this.$store.state.session.challenge
    },
    redirectUri() {
      return this.$store.state.session.redirectUri
    },
    session() {
      return this.$store.state.session.session
    }
  },
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    },
  },
  beforeMount() {
  },
  mounted() {
    if (isEmpty(this.session)) {
      window.location.href = this.marianaTekLoginHref
    } else {
      this.$store.dispatch('fifth-tab-post/find', {
        query: {
          $limit: 50,
          $sort: {
            publishAt: -1,
          },
          publishAt: {
            $lte: DateTime.now().setZone('America/Los_Angeles').startOf('day').toISO(),
          },
          unpublishAt: {
            $gte: DateTime.now().setZone('America/Los_Angeles').endOf('day').toISO(),
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">

</style>