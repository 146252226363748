<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="4" class="text-center mt-8">
        <v-sheet class="py-5" elevation="1">
          <heart-loader />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeartLoader from "@/components/heartLoader"

export default {
  name: 'OAuthCallbackView',

  components: {
    HeartLoader,
  },

  data() {
    return {
      authorizationCode: null,
      clientId: 'eQfycs5zGlkhajIXy6yZszZviLkK1da3YLIcQwQS',
    }
  },

  computed: {
    challenge() {
      return this.$store.state.challenge
    }
  },

  mounted() {
    console.log('MOUNTED')
    this.$store.state.session.code = this.$route.query.code
    this.$store.dispatch('authenticate')
  }
}
</script>

<style lang="sass" scoped>
</style>