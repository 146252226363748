import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardPage from '../views/DashboardPage.vue'
import OauthCallbackView from '../views/OauthCallbackView.vue'

Vue.use(VueRouter)
//this is the router
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/oauth/callback',
    name: 'OauthCallbackRoute',
    component: OauthCallbackView,
    meta: { requiresAuth: false }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
