import axios from 'axios'
import router from '../router'

export const session = {
  state: {
    session: {},
    challenge: '16E79D79647AC8CFD8CBE6708C28FB3',
    baseEccoUrl: process.env.VUE_APP_BASE_ECCO_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    state: '39fh03fhj30gh0jfowej0fifjasodin0jhkg2',
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    marianaTekUri: process.env.VUE_APP_MARIANA_TEK_URI,
    marianaBaseUri: process.env.VUE_APP_BASE_MARIANA_URI,
    code: null,
  },
  mutations: {
    setSession: (state, val) => {
      state.session = val
    },
    setUser: (state, val) => {
      state.user = val
    },
    setChallenge: (state, val) => {
      state.challenge = val
    },
    setCode: (state, val) => {
      state.code = val
    },
  },
  actions: {
    authenticate: async ({ commit, state }) => {
      console.log('AUTHENTICATING')
      console.log(state)
      axios.post(`${state.marianaBaseUri}/o/token/?client_id=${state.clientId}&code_verifier=${state.challenge}&code=${state.code}&grant_type=authorization_code&redirect_uri=${state.redirectUri}`)
      .then(async (data) => {
        console.log(data)
        commit('setSession', data.data)
        //await dispatch('getProfile')
        router.push({ name: 'Home' })
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    },
  },
  getters: {}
}